import styled from "styled-components";
export const StyledArrow = _get__("styled").div`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  box-shadow: unset;
  border-bottom: 0.5px solid ${_ref => {
  let {
    theme
  } = _ref;
  return theme.color?.gray?.three;
}};
  border-right: 0.5px solid ${_ref2 => {
  let {
    theme
  } = _ref2;
  return theme.color?.gray?.three;
}};
`;
export const StyledTooltip = _get__("styled").div`
  position: absolute;
  top: ${props => props.tooltipPosition?.y ?? 0}px;
  left: ${props => props.tooltipPosition?.x ?? 0}px;
  visibility: ${props => props.visibility};
  pointer-events: none;
  z-index: 20;
  ${_ref3 => {
  let {
    scalableTransform,
    zoomableTransform
  } = _ref3;
  if (scalableTransform.zoom) {
    return `zoom: ${1 / scalableTransform.scale / zoomableTransform.scale};`;
  }
  return `transform: scale3d(${1 / scalableTransform.scale / zoomableTransform.scale}, ${1 / scalableTransform.scale / zoomableTransform.scale}, 1);`;
}};
  transform-origin: ${props => {
  switch (props.tooltipPosition?.arrowPlacement) {
    case "placement-left":
      return "center left;";
    case "placement-right":
      return "center right;";
    case "placement-top":
      return "top center;";
    default:
      return "bottom center;";
  }
}};

  ${_get__("StyledArrow")} {
    ${_ref4 => {
  let {
    tooltipPosition
  } = _ref4;
  switch (tooltipPosition?.arrowPlacement) {
    case "placement-left":
      return "transform: rotate(135deg);" + `top: calc( 50% - ${tooltipPosition?.arrowOffset}px );` + "left: -8px;";
    case "placement-right":
      return "transform: rotate(315deg);" + `top: calc( 50% - ${tooltipPosition?.arrowOffset}px );` + "left: calc( 100% - 8px );";
    case "placement-top":
      return "transform: rotate(225deg);" + "bottom: calc( 100% - 8px );" + `left: calc( 50% - ${tooltipPosition?.arrowOffset}px );`;
    default:
      return "transform: rotate(45deg);" + "bottom: -8px;" + `left: calc( 50% - ${tooltipPosition?.arrowOffset}px );`;
  }
}};
  }
`;
export const StyledTooltipContent = _get__("styled").div`
  ${_ref5 => {
  let {
    theme
  } = _ref5;
  return theme.tooltip.container;
}};
  ${_ref6 => {
  let {
    theme
  } = _ref6;
  return theme.tooltip.content;
}};
  padding: 0;
  border-radius: 3px;
  border: 0.5px solid ${_ref7 => {
  let {
    theme
  } = _ref7;
  return theme.color?.gray?.three;
}};
  box-shadow: unset;
  text-align: center;
`;
export const StyledSeatDescriptionContainer = _get__("styled").div`
  padding: 3px 24px;
  text-align: left;
`;
export const StyledTicketValue = _get__("styled").span`
  font-weight: 700;
`;
export const StyledSeatDescription = _get__("styled").h3`
  ${_ref8 => {
  let {
    theme
  } = _ref8;
  return theme.textStyles.h3;
}};
  white-space: nowrap;
  margin: 16px 0 ${_ref9 => {
  let {
    theme
  } = _ref9;
  return theme.spacing.xsmall;
}} 0;
`;
export const StyledSeatPrice = _get__("styled").p`
  font-size: ${_ref10 => {
  let {
    theme
  } = _ref10;
  return theme.text.size.paragraph.sixteen;
}};
  color: ${_ref11 => {
  let {
    theme
  } = _ref11;
  return theme.color.black;
}};
  font-weight: bold;
  margin-top: 0;
`;
export const StyledEach = _get__("styled").span`
  font-size: ${_ref12 => {
  let {
    theme
  } = _ref12;
  return theme.text.size.paragraph.fourteen;
}};
  color: ${_ref13 => {
  let {
    theme
  } = _ref13;
  return theme.color.gray.five;
}};
  font-weight: 400;
`;
export const IconTitle = _get__("styled").div`
  display: flex;
  align-items: center;
`;
export const MarkerIcon = _get__("styled")("svg")`
  cursor: pointer;
  .${_ref14 => {
  let {
    iconName
  } = _ref14;
  return iconName;
}}-react-svg-icon-primary-color {
    fill: ${_ref15 => {
  let {
    theme,
    iconColor
  } = _ref15;
  return iconColor ?? theme.color?.teal?.accent;
}};
  }
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-right: 6px;
  stroke-opacity: 0.2;
  stroke-width: ${_ref16 => {
  let {
    strokeWidth
  } = _ref16;
  return strokeWidth;
}}px;
  stroke: none;
`;
export const Title = _get__("styled").span`
  font-size: ${_ref17 => {
  let {
    theme
  } = _ref17;
  return theme.text.size.paragraph.fourteen;
}};
  font-weight: 400;
`;
export const StyledLevelSectionTooltipContainer = _get__("styled").div`
  display: flex;
  flex-direction: column;

  img {
    border-radius: 3px 3px 0 0;
    height: 121px;
    width: 304px;
    object-fit: cover;
    object-position: center ${props => `${props.vfsVerticalPosition}%`};
    @media (max-width: ${_ref18 => {
  let {
    theme
  } = _ref18;
  return theme.breakpoint?.sm;
}}) {
      display: none;
    }
  }
`;
export const StyledLevelSectionValues = _get__("styled").div`
  padding: 3px 8px;
`;
function _getGlobalObject() {
  try {
    if (!!global) {
      return global;
    }
  } catch (e) {
    try {
      if (!!window) {
        return window;
      }
    } catch (e) {
      return this;
    }
  }
}
;
var _RewireModuleId__ = null;
function _getRewireModuleId__() {
  if (_RewireModuleId__ === null) {
    let globalVariable = _getGlobalObject();
    if (!globalVariable.__$$GLOBAL_REWIRE_NEXT_MODULE_ID__) {
      globalVariable.__$$GLOBAL_REWIRE_NEXT_MODULE_ID__ = 0;
    }
    _RewireModuleId__ = __$$GLOBAL_REWIRE_NEXT_MODULE_ID__++;
  }
  return _RewireModuleId__;
}
function _getRewireRegistry__() {
  let theGlobalVariable = _getGlobalObject();
  if (!theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__) {
    theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__ = Object.create(null);
  }
  return theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__;
}
function _getRewiredData__() {
  let moduleId = _getRewireModuleId__();
  let registry = _getRewireRegistry__();
  let rewireData = registry[moduleId];
  if (!rewireData) {
    registry[moduleId] = Object.create(null);
    rewireData = registry[moduleId];
  }
  return rewireData;
}
(function registerResetAll() {
  let theGlobalVariable = _getGlobalObject();
  if (!theGlobalVariable['__rewire_reset_all__']) {
    theGlobalVariable['__rewire_reset_all__'] = function () {
      theGlobalVariable.__$$GLOBAL_REWIRE_REGISTRY__ = Object.create(null);
    };
  }
})();
var INTENTIONAL_UNDEFINED = '__INTENTIONAL_UNDEFINED__';
let _RewireAPI__ = {};
(function () {
  function addPropertyToAPIObject(name, value) {
    Object.defineProperty(_RewireAPI__, name, {
      value: value,
      enumerable: false,
      configurable: true
    });
  }
  addPropertyToAPIObject('__get__', _get__);
  addPropertyToAPIObject('__GetDependency__', _get__);
  addPropertyToAPIObject('__Rewire__', _set__);
  addPropertyToAPIObject('__set__', _set__);
  addPropertyToAPIObject('__reset__', _reset__);
  addPropertyToAPIObject('__ResetDependency__', _reset__);
  addPropertyToAPIObject('__with__', _with__);
})();
function _get__(variableName) {
  let rewireData = _getRewiredData__();
  if (rewireData[variableName] === undefined) {
    return _get_original__(variableName);
  } else {
    var value = rewireData[variableName];
    if (value === INTENTIONAL_UNDEFINED) {
      return undefined;
    } else {
      return value;
    }
  }
}
function _get_original__(variableName) {
  switch (variableName) {
    case "styled":
      return styled;
    case "StyledArrow":
      return StyledArrow;
  }
  return undefined;
}
function _assign__(variableName, value) {
  let rewireData = _getRewiredData__();
  if (rewireData[variableName] === undefined) {
    return _set_original__(variableName, value);
  } else {
    return rewireData[variableName] = value;
  }
}
function _set_original__(variableName, _value) {
  switch (variableName) {}
  return undefined;
}
function _update_operation__(operation, variableName, prefix) {
  var oldValue = _get__(variableName);
  var newValue = operation === '++' ? oldValue + 1 : oldValue - 1;
  _assign__(variableName, newValue);
  return prefix ? newValue : oldValue;
}
function _set__(variableName, value) {
  let rewireData = _getRewiredData__();
  if (typeof variableName === 'object') {
    Object.keys(variableName).forEach(function (name) {
      rewireData[name] = variableName[name];
    });
    return function () {
      Object.keys(variableName).forEach(function (name) {
        _reset__(variableName);
      });
    };
  } else {
    if (value === undefined) {
      rewireData[variableName] = INTENTIONAL_UNDEFINED;
    } else {
      rewireData[variableName] = value;
    }
    return function () {
      _reset__(variableName);
    };
  }
}
function _reset__(variableName) {
  let rewireData = _getRewiredData__();
  delete rewireData[variableName];
  if (Object.keys(rewireData).length == 0) {
    delete _getRewireRegistry__()[_getRewireModuleId__];
  }
  ;
}
function _with__(object) {
  let rewireData = _getRewiredData__();
  var rewiredVariableNames = Object.keys(object);
  var previousValues = {};
  function reset() {
    rewiredVariableNames.forEach(function (variableName) {
      rewireData[variableName] = previousValues[variableName];
    });
  }
  return function (callback) {
    rewiredVariableNames.forEach(function (variableName) {
      previousValues[variableName] = rewireData[variableName];
      rewireData[variableName] = object[variableName];
    });
    let result = callback();
    if (!!result && typeof result.then == 'function') {
      result.then(reset).catch(reset);
    } else {
      reset();
    }
    return result;
  };
}
export { _get__ as __get__, _get__ as __GetDependency__, _set__ as __Rewire__, _set__ as __set__, _reset__ as __ResetDependency__, _RewireAPI__ as __RewireAPI__ };
export default _RewireAPI__;